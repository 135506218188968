<template>
    <el-dialog :visible.sync="sta.show" width="750px">
        <span slot="title">增加/修改 检查记录</span>
        <div class="row">
            <div class="col-md-12">
                <el-row>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>选择类型：</b></label>
                            <el-select v-model="model.TYPE_ID" placeholder="请选择" style="width: 60%"
                                :disabled="type_disa" @change="typeChange">
                                <el-option v-for="item in typeList" :key="item.Key" :label="item.Value"
                                    :value="item.Key">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1" style="float: left;"><b>检查内容：</b></label>
                            <el-table :data="itemList" style="width: 80%;margin-left: 89px;" border
                                :header-cell-style="headerCellStyle">
                                <el-table-column prop="NAME" label="项目" align="center">
                                </el-table-column>
                                <el-table-column label="情况" align="center">
                                    <template slot-scope="scope">
                                        <!-- <el-select v-model="scope.row.Qualified" placeholder="请选择" size="small"
                                            style="width: 100px;">
                                            <el-option label="合格" :value="0"></el-option>
                                            <el-option label="不合格" :value="1"></el-option>
                                        </el-select> -->
                                        <el-radio v-model="scope.row.STATUS" :label="0" @change="ztChange"
                                            :disabled="model.STATUS==2">合格</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot-scope="scope">
                                        <!-- <el-select v-model="scope.row.Status" placeholder="请选择" size="small"
                                            style="width: 100px;">
                                            <el-option :label="item.Name" :value="item.Id" v-for="item in Status"
                                                :key="'zt_'+item.Id"></el-option>
                                        </el-select> -->
                                        <el-radio v-model="scope.row.STATUS" :label="1" @change="ztChange"
                                            :disabled="model.STATUS==2">整改</el-radio>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>检查位置：</b></label>
                            <el-input type="text" v-model="model.LOCATION" style="width: 60%"
                                :disabled="model.STATUS==2" />
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>检查日期：</b></label>
                            <el-date-picker v-model="model.DATE" value-format="yyyy-MM-dd" type="date"
                                style="width: 60%" placeholder="选择检查日期" :disabled="model.STATUS==2">
                            </el-date-picker>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-show="iszg">
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1"><b>存在问题：</b></label>
                            <el-input type="textarea" :rows="2" v-model="model.PROBLEM" style="width: 80%"
                                :disabled="model.STATUS==2" />
                        </div>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="24">
                        <div class="sign">
                            <label class="label1"><b>签名：</b></label>
                            <sign-canvas v-if="!model.SIGN_KV" class="sign-canvas" ref="SignCanvas"
                                :options="options" />
                            <img v-if="model.SIGN_KV" :src="model.SIGN_KV.Value" alt="">
                            <div class="btns">
                                <el-button type="danger" size="small" @click="canvasClear()"
                                    style="margin-right: 20px;">清空</el-button>
                                <el-button type="primary" size="small" @click="saveAsImg()">保存</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row> -->
                <el-row v-show="iszg">
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>整改人：</b></label>
                            <el-select v-model="model.RECTIFY_ID" placeholder="请选择" style="width: 60%"
                                :disabled="model.STATUS==2" filterable>
                                <el-option v-for="item in teacherList" :key="item.Id" :label="item.Name"
                                    :value="item.Id">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>

                <!-- <el-row v-if="iszg">
                    <el-col :span="24">
                        <div class="form-group" style="display: flex">
                            <label class="label1"><b>整改照片：</b></label>
                            <uploadImg @getImgUrl="getImgUrl" :limit="6"></uploadImg>
                        </div>
                    </el-col>
                </el-row>

                <el-row v-if="iszg">
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1"><b>整改说明：</b></label>
                            <el-input type="text" v-model="model.RECTIFY_DESC" style="width: 80%" />
                        </div>
                    </el-col>
                </el-row> -->
            </div>
        </div>
        <div slot="footer" v-loading="sta.loading">
            <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
            <el-button icon="el-icon-check" type="success" @click="submit" v-if="model.STATUS!=2">确认</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import uploadImg from "@/components/uploadImg";
    export default {
        name: "edit",
        props: ['typeList'],
        components: { uploadImg },
        data() {
            return {
                model: {
                    TYPE_ID: 0,
                    DATE: "",
                    LOCATION: "",
                    PROBLEM: "",
                    CONTENT_JSON: "",
                    RECTIFY_ID: 0,
                },
                // user: this.whale.user.get(),
                fileList: [],
                sta: {
                    show: false,
                    loading: false
                },
                info: {},
                // func: [],
                // activityType: '',
                // activityTypes: ['会议', '活动', '上课', '其它'],
                // environmentStates: ['好', '差'],
                Status: [{ Id: 0, Name: '无需整改' }, { Id: 1, Name: '待整改' }, { Id: 2, Name: '已整改' }],
                // toolStates: ['完整', '损耗'],
                isCreate: false,
                teacherList: [],
                // typeList: [],
                itemList: [],
                allItemList: [],
                iszg: false,
                type_disa: false,
                // signUrl: "",
                options: {
                    lastWriteSpeed: 1, //书写速度 [Number] 可选
                    lastWriteWidth: 2, //下笔的宽度 [Number] 可选
                    lineCap: "round", //线条的边缘类型 [butt]平直的边缘 [round]圆形线帽 [square]    正方形线帽
                    lineJoin: "round", //线条交汇时边角的类型  [bevel]创建斜角 [round]创建圆角 [miter]创建尖角。
                    canvasWidth: 300, //canvas宽高 [Number] 可选
                    canvasHeight: 150, //高度  [Number] 可选
                    isShowBorder: true, //是否显示边框 [可选]
                    bgColor: "#fff", //背景色 [String] 可选
                    borderWidth: 1, // 网格线宽度  [Number] 可选
                    borderColor: "#ccc", //网格颜色  [String] 可选
                    writeWidth: 5, //基础轨迹宽度  [Number] 可选
                    maxWriteWidth: 30, // 写字模式最大线宽  [Number] 可选
                    minWriteWidth: 5, // 写字模式最小线宽  [Number] 可选
                    writeColor: "#101010", // 轨迹颜色  [String] 可选
                    isSign: true, //签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
                    imgType: "png" //下载的图片格式  [String] 可选为 jpeg  canvas本是透明背景的
                },
            }
        },
        created() {
            this.getItems()
            this.getTeachers()
            // this.getSign()
        },
        methods: {
            init(model) {
                this.sta = { show: true, loading: false }
                if (model != undefined && model != null) {
                    this.model = JSON.parse((JSON.stringify(model)));
                    this.itemList = model.ITEM_LIST;
                    this.ztChange()
                    this.type_disa = true
                } else {
                    this.model = {};
                    this.itemList = []
                    this.iszg = false
                    this.type_disa = false
                }
            },
            getImgUrl(val) {
                this.model.RECTIFY_IMAGE = val.join(',')
            },
            onRemove(file) {
                // console.log('onRemove', file)
            },
            onChange(file, fileList) {
                // console.log('fileList', fileList)
                // console.log('this.fileList', this.fileList)
                let self = this;
                this.whale.image.compress({
                    file: file.raw,
                    callback(cf) {
                        self.whale.image.toB64({
                            file: cf,
                            callback(b64) {
                                self.model.PIC_B64 = b64;
                                // self.whale.remote.getResult({
                                //   url: "/api/School/FUNC/FuncRoomUse/UpUseOrManPicture",
                                //   data:{
                                //     b64,
                                //     Id:self.model.ID,
                                //     IsOrganize:0,
                                //   },
                                //   completed: function () {}
                                // })
                            }
                        })
                    }
                })
            },
            typeChange(val) {
                this.itemList = this.allItemList.filter(x => x.TYPE_ID == val)
                this.ztChange()
            },
            getTeachers() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/DOC/PowerApi/GetTeachers",
                    data: { isPT: false },
                    completed: function (its) {
                        self.teacherList = its.DATA;
                    }
                })
            },
            getItems() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/INS/TypeApi/GetItemKvs",
                    completed: function (its) {
                        self.allItemList = its.DATA;
                    }
                })
            },
            ztChange() {
                this.iszg = this.itemList.filter(x => x.STATUS == 1).length > 0 ? true : false;
            },
            submit() {
                let self = this

                self.model.CONTENT_JSON = JSON.stringify(self.itemList)
                if (!self.iszg) {
                    self.model.RECTIFY_ID = 0
                    self.model.PROBLEM = ""
                }
                // console.log(self.model)
                // console.log(self.model.TYPE_ID)

                if (self.model.TYPE_ID == undefined) {
                    self.whale.toast.warn("请选择检查类型")
                    return
                }

                self.whale.remote.getResult({
                    url: "/api/School/INS/RecordApi/SaveRecord",
                    data: self.model,
                    finally() { self.sta.loading = false; },
                    completed: function () {
                        self.sta.show = false;
                        self.$emit("on-saved");
                    }
                })

            },
            headerCellStyle({ row, column, rowIndex, columnIndex }) {
                if (row[0].level === 1) {
                    row[1].colSpan = 2;
                    row[2].colSpan = 0;
                    if (columnIndex === 2) {
                        return { display: "none" };
                    }
                }
                return {
                    color: "#333333",
                    fontWeight: "bold",
                    textAlign: "center",
                };
            },
            canvasClear() {
                if (this.model.SIGN_KV) {
                    this.model.SIGN_KV = null
                } else {
                    this.$refs.SignCanvas.canvasClear();
                }
            },
            // 保存图片
            saveAsImg() {
                const img = this.$refs.SignCanvas.saveAsImg();
                // console.log(img)
                if (img) {
                    const parts = img.split(";base64,");
                    // console.log("parts", parts);
                    const contentType = parts[0].split(":")[1];
                    const raw = window.atob(parts[1]);
                    // console.log("raw", raw);
                    const rawLength = raw.length;
                    const uInt8Array = new Uint8Array(rawLength);

                    // 将 Base64 转换为 Uint8Array
                    for (let i = 0; i < rawLength; ++i) {
                        uInt8Array[i] = raw.charCodeAt(i);
                    }

                    // 创建 Blob 对象
                    let blob = new Blob([uInt8Array], { type: contentType });
                    // console.log(blob);
                    this.H5SignUploadPost(blob);
                } else {
                    this.$message.warning('没有签名')
                }
            },
            H5SignUploadPost(blob) {
                const formData = new FormData();
                formData.append("file", blob, "sign.png");
                let self = this;
                this.whale.remote.uploadSign({
                    url: 'api/School/INS/RecordApi/SignPcUpLoad',
                    data: formData,
                    completed: function (resp) {
                        // self.signUrl = resp.Data.DATA.OSS_URL
                        self.model.SIGN_KV = {
                            Key: resp.Data.DATA.NAME,
                            Value: resp.Data.DATA.OSS_URL
                        }
                        // self.action.model.MENDER_SIGN_PIC_NAME = resp.Data.DATA.NAME
                        // console.log('sign', self.model.SIGN_KV.Value)
                    }
                })
            },
            getSign() {
                let self = this
                self.whale.remote.getResult({
                    url: 'api/School/INS/RecordApi/GetSignPc',
                    completed(res) {
                        self.model.SIGN_KV = {
                            Key: res.NAME,
                            Value: res.OSS_URL
                        }
                        // console.log(self.model.SIGN_KV)
                        // self.model.SIGN_KV.Value = res.OSS_URL
                        // self.model.SIGN_KV.Key = res.NAME
                    }
                })
            }
        },
        watch: {
            // itemList: {
            //     handler(nv, ov) {
            //         let self = this
            //         self.iszg = nv.filter(x => x.Status == 1).length > 0 ? true : false;
            //     },
            //     deep: true
            // }
        },
    }
</script>

<style scoped>
    .label1 {
        width: 6em;
        text-align: right;
        margin-right: 5px;
        /* float: left; */
    }

    .sign .sign-tit {
        padding: 16px 16px 8px;
        color: #969799;
        font-size: 14px;
        line-height: 16px;
    }

    .sign .sign-canvas {
        /* display: block; */
        /* margin-left: 50px; */
    }

    .sign img {
        display: block;
        margin: 0 auto;
        width: 300px;
        height: 120px;
    }

    .sign .btns {
        margin-bottom: 10px;
        margin-left: 150px;
        /* text-align: left; */
    }
</style>